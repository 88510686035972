import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "../components/navBar/navBar";
import SideNav from "../components/sideNav/sideNav";
import AboutStore from "../screens/panel/aboutStore/aboutStore";
import AddProduct from "../screens/panel/addProduct/addProduct";
import Financials from "../screens/panel/financials/financials";
import Plans from "../screens/panel/plans/plans";
import Home from "../screens/panel/home/home";
import Review from "../screens/panel/review/review";
import { getMethod } from "../utils/services/api";
import urls from "../utils/services/apiUrl.json";
import HelpCenter from "../screens/panel/helpCenter/helpCenter";
import Promotions from "../screens/panel/promotions/banner/banner";
import Catalogue from "../screens/panel/catalogue/catalogue";
import Listing from "../screens/panel/catalogue/catalogue";
import Collection from "../screens/panel/catalogue/collection/collection";
import Stores from "../screens/panel/stores/stores";
import Settings from "../screens/panel/settings/general/generalSettings";
import GeneralSettings from "../screens/panel/settings/general/generalSettings";
import Faqs from "../screens/panel/settings/faqs/faqs";
import Faq from "../screens/panel/settings/faqs/faq";
import BankDetails from "../screens/panel/Transaction/TransactionListing";
import ShippingDelivery from "../screens/panel/settings/shippingDelivery/shippingDelivery";
import Suppliers from "../screens/panel/contact/supplier/suppliers";
import Supplier from "../screens/panel/contact/supplier/supplier";
import Customers from "../screens/panel/contact/customers/customers";
import Banner from "../screens/panel/promotions/banner/banner";
import Deals from "../screens/panel/promotions/deals/deals";
import Discounts from "../screens/panel/promotions/discounts/discounts";
import Discount from "../screens/panel/promotions/discounts/discount";
import OrdersTab from "../screens/panel/orders/ordersTab";
import ShopDetails from "../screens/panel/stores/components/inReview/components/shopDetails/ShopDetails";
import OnlineEnquiry from "../screens/panel/OnlineEnquiry/OnlineEnquiry";
import StoreDetails from "../screens/panel/OnlineEnquiry/storeDetails/StoreDetails";
import AppUsers from "../screens/panel/appUsers/AppUsers";
import Notification from "../screens/panel/OnlineEnquiry/notification/Notification";
import { getUserRole } from "../utils/common";
import MasterData from "../screens/panel/masterData/MasterData";
import Order from "../screens/panel/orders/orderAdmin/Order";
import Role from "../screens/panel/masterData/role/role";
import Territories from "../screens/panel/masterData/territories/territories";
import UserDetails from "../screens/panel/appUsers/userDetails/userDetails";
import ShortlistedDetails from "../screens/panel/stores/components/shortlisted/components/shortlistedDetails";
import ApproveStoreDetails from "../screens/panel/stores/components/approved/approvedStore/approveStoreDetails";
import OnHoldStore from "../screens/panel/stores/components/onHold/onHoldStore/onHoldStore";
import PrivacyPolicies from "../screens/panel/masterData/privacyPolicy/privacyPolicies";
import Terms from "../screens/panel/masterData/termsOf USe/terms";
import OrderStores from "../screens/panel/orders/orderStores";
import Campaign from "../screens/panel/campaign/campaign";
import CampaignDetails from "../screens/panel/campaign/campaignDetails";
import Brands from "../screens/panel/brands/brands";
import Driver from "../screens/panel/Driver/Driver";
import DriverInReviewDetail from "../screens/panel/Driver/Components/InReviewTab/InReviewTabDriver/DriverInReviewDetail/DriverInReviewDetail";
import ApprovedDriverDetail from "../screens/panel/Driver/Components/Approved/ApprovedDriverDetail/ApprovedDriverDetail";
import OnHoldDriverDetail from "../screens/panel/Driver/Components/OnHold/OnHoldDriverDetail/OnHoldDriverDetail";
import CostParameter from "../screens/panel/CostParameter/CostParameter";
import ExportData from "../screens/panel/masterData/exportData/ExportData";

export default function Panel() {
    const pathname = window.location.pathname;
    const [menuStatus, setMenuStatus] = useState(false);
    const [onBoarding, setOnBoarding] = useState(false);
    window.$panelActive = menuStatus;

    useEffect(() => {
        onBoardStatus();
    }, []);

    const onBoardStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            setOnBoarding(response?.data?.status);
        }).catch((error) => {
            console.log(error);
        });
    };

    const Panel = [
        {
            path: "/aboutStore",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar
                        progressUpdate={window.$progessPercentage}
                        currentStatus={"?of=store"}
                        setMenu={() => setMenuStatus(!menuStatus)}
                    />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <AboutStore aboutStatus="AboutStore" />
                    </div>
                </div>
            ),
        },
        {
            path: "/addProduct",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar
                        progressUpdate={window.$progessPercentage}
                        currentStatus={"?of=product"}
                        setMenu={() => setMenuStatus(!menuStatus)}
                    />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <AddProduct />
                    </div>
                </div>
            ),
        },
        {
            path: "/financials",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar
                        progressUpdate={window.$progessPercentage}
                        currentStatus={"?of=financial"}
                        setMenu={() => setMenuStatus(!menuStatus)}
                    />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Financials onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/plans",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar
                        progressUpdate={window.$progessPercentage}
                        currentStatus={"?of=plans"}
                        setMenu={() => setMenuStatus(!menuStatus)}
                    />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Plans aboutStatus="Plans" />
                    </div>
                </div>
            ),
        },
        {
            path: "/review",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar
                        progressUpdate={window.$progessPercentage}
                        currentStatus={"?of=review"}
                        setMenu={() => setMenuStatus(!menuStatus)}
                    />
                    <div style={{ display: "flex", overflow: "hidden" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Review />
                    </div>
                </div>
            ),
        },
    ];

    const PanelAfterOnboarding = [
        {
            path: "/home",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} /> <Home />
                    </div>
                </div>
            ),
        },
        {
            path: "/aboutStore",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <AboutStore aboutStatus="AboutStore" />
                    </div>
                </div>
            ),
        },
        {
            path: "/contacts",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Customers />
                    </div>
                </div>
            ),
        },
        {
            path: "/contacts/customers",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Customers />
                    </div>
                </div>
            ),
        },
        {
            path: "/contacts/suppliers",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Suppliers />
                    </div>
                </div>
            ),
        },
        {
            path: "/contacts/suppliers/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Supplier />
                    </div>
                </div>
            ),
        },
        {
            path: "/helpCenter",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <HelpCenter />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Stores menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/inreview/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <ShopDetails />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/inshortlisted/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <ShortlistedDetails />
                    </div>
                </div>
            ),
        },
        {
            path: "/catalogue",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Catalogue />
                    </div>
                </div>
            ),
        },
        {
            path: "/catalogue/listing",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Listing />
                    </div>
                </div>
            ),
        },
        {
            path: "/catalogue/collection",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Collection />
                    </div>
                </div>
            ),
        },
        {
            path: "/promotions",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Promotions />
                    </div>
                </div>
            ),
        },
        {
            path: "/promotions/banners",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Banner />
                    </div>
                </div>
            ),
        },
        {
            path: "/promotions/deals",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Deals />
                    </div>
                </div>
            ),
        },
        {
            path: "/promotions/discounts",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Discounts />
                    </div>
                </div>
            ),
        },
        {
            path: "/promotions/discount/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Discount />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <OrderStores />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders/listing",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Order />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders/details/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <OrdersTab />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Settings />
                    </div>
                </div>
            ),
        },
        {
            path: "/addProduct",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <AddProduct />
                    </div>
                </div>
            ),
        },
        {
            path: "/transaction",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <BankDetails />
                    </div>
                </div>
            ),
        },
        {
            path: "/review",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />{" "}
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Review />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/generalSettings",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <GeneralSettings />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/faqs",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Faqs />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/faqs/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Faq />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/plans",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Plans />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/financials",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Financials />
                    </div>
                </div>
            ),
        },
        {
            path: "/settings/shippingDelivery",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ShippingDelivery />
                    </div>
                </div>
            ),
        },
    ];

    const PanelAfterOnboardingAdmin = [
        {
            path: "/onlineenquiry",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <OnlineEnquiry menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/onlineenquiry/storedetails",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <StoreDetails menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/brands",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Brands menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/notifications",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Notification />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Stores menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/inreview/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ShopDetails menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/inshortlisted/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ShortlistedDetails menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/approved/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ApproveStoreDetails menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/stores/onHold/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <OnHoldStore menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/campaign",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Campaign menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/campaign/details",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <CampaignDetails menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/costparameters",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <CostParameter menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <OrderStores menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders/listing/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Order menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        // {
        //     path: "/orders/details/:store_id/:order_id",
        //     exact: true,
        //     render: () => (
        //         <div style={{ width: "100%", backgroundColor: "#fff" }}>
        //             <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
        //             <div style={{ display: "flex" }}>
        //                 <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
        //                 <OrdersTab menu={menuStatus} onBoardingStatus={onBoarding} />
        //             </div>
        //         </div>
        //     ),
        // },
        {
            path: "/orders/details/:store_id/:order_id/:order",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <OrdersTab menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/orders/details/:store_id/:order_id/:order/:express",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <OrdersTab menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/driver",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />{" "}
                        <Driver menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/driver/inreview/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <DriverInReviewDetail menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/driver/approved/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ApprovedDriverDetail menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/driver/onHold/:id",
            exact: true,
            render: () => (
                <div style={{ width: "100%" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <OnHoldDriverDetail menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <MasterData menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata/role",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Role menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata/territories",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Territories menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata/privacy_policies",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <PrivacyPolicies menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata/terms",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <Terms menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/masterdata/export",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <ExportData menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/appusers",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <AppUsers menu={menuStatus} onBoardingStatus={onBoarding} />
                    </div>
                </div>
            ),
        },
        {
            path: "/appusers/userdetails",
            exact: true,
            render: () => (
                <div style={{ width: "100%", backgroundColor: "#fff" }}>
                    <Navbar setMenu={() => setMenuStatus(!menuStatus)} />
                    <div style={{ display: "flex" }}>
                        <SideNav menu={menuStatus} onBoardingStatus={onBoarding} />
                        <UserDetails />
                    </div>
                </div>
            ),
        },
    ];

    const sideNavModules = () => {
        if (getUserRole() === "super-admin") {
            return PanelAfterOnboardingAdmin.map((route, index) => {
                return (
                    <Route key={index} path={route.path} exact render={route.render} />
                );
            });
        } else if (!onBoarding) {
            return Panel.map((route, index) => {
                return (
                    <Route key={index} path={route.path} exact render={route.render} />
                );
            });
        } else {
            return PanelAfterOnboarding.map((route, index) => {
                return (
                    <Route key={index} path={route.path} exact render={route.render} />
                );
            });
        }
    };

    return <Switch>{sideNavModules()}</Switch>;
}
